:root {
  /* Fixed heights */
  --header-height: 76px;
  --footer-height: 128px;

  /* Colors
   *****************************************************************************
   Gray and brand colors for use across application
  */
  --roboto: Roboto-Regular;
  --charc: #0c2a31; /* "Dark Charc" */
  --charc-medium: #394859;
  --charc-transp: rgba(0, 0, 0, 0.15);
  --charc-transp-darker: rgba(0, 0, 0, 0.35);
  --grey-darkest: #26383d;
  --hover-grey: #dee1e4;
  --grey-darker: #4f5b66;
  --grey-dark: #8d9ca9;
  --grey: #a7adba;
  --grey-transp: rgba(57, 72, 89, 0.25);
  --grey-light: #dddddd;
  --grey-light-alt: #d4d3d3;
  --grey-lighter: #f4f4f4;
  --grey-lighter-alt: #eee;
  --grey-medium: #a3a3a3;
  --grey-medium-alt: #bbc0c4;
  --red: #ec5f67;
  --red-dark: #d6414a;
  --red-darker: #c53727;
  --red-darkest: #e2200a;
  --orange: #f99157;
  --yellow: #fac863;
  --green: #99c794;
  --blue-light: #5fb3b3;
  --light-shiny-blue: #00a4d6;
  --blue: #007ea8; /* "Berg Blue" */
  --blue-dark: #0f6c8d; /* "Berg Tint 2" */
  --blue-darker: #017295; /* "Berg Tint 3" */
  --blue-darkest: #014155; /* "Berg Tint 4" */
  --blue-link: #3d6aa6; /* Default link color */
  --blue-lighter: #f5f9fc; /* "Berg Tint 1" */
  --blue-lighter-alt: #00c3ff;
  --aqua-blue: #26b6df;
  --sky-blue: #c9f0fb;
  --light-grey: #c8c8c8;
  --very-light-grey: #f4f4f4;
  --grey-border: #dee1e0;
  --dark-grey: #143138;
  --purple: #c594c5;
  --brown: #ab7967;
  --black: #000000;
  --white: #ffffff;
  --white-transp: rgba(255, 255, 255, 0.35);
  --white-transp-darker: rgba(255, 255, 255, 0.15);
  --highlightColor: hwb(190, 35%, 20%);

  --successColor: #3c763d;
  --warningColor: #8a6d3b; /* #EFBF4F;*/
  --errorColor: #e34c53;
  --warningIcon: #f0c043;
  /* End Colors */

  /* Typography
   *****************************************************************************
   Font, line-height, and color for body text
  */

  --font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-serif: Georgia, "Times New Roman", Times, serif;
  --font-family-roboto: "Roboto";
  --font-family-simplon: "Simplon Norm";
  --font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
  --font-family-zawgyi-one: "Zawgyi-One";
  --font-family-base: var(--font-family-sans-serif);
  --font-family-simplon-bold: "SimplonNorm-Bold";
  --font-family-simplon-light: "SimplonNorm-Light";
  --font-family-simplon-medium: "SimplonNorm-Medium";

  --font-size-base: 15px;
  --font-size-h1: calc(var(--font-size-base) * 2.6);
  --font-size-h2: calc(var(--font-size-base) * 2.1);
  --font-size-h3: calc(var(--font-size-base) * 1.7);
  --font-size-h4: calc(var(--font-size-base) * 1.25);
  --font-size-h5: var(--font-size-base);
  --font-size-h6: calc(var(--font-size-base) * 0.85);
  --font-size-large: calc(var(--font-size-base) * 1.25);
  --font-size-small: calc(var(--font-size-base) * 0.8);
  --font-size-very-small: calc(var(--font-size-base) * 0.73);
  --font-size-normal: var(--font-size-base);
  --font-size-medium: calc(var(--font-size-base) * 0.9333333);
  --font-size-extra-large: calc(var(--font-size-base) * 1.4666666);

  --font-weight-normal: normal;
  --font-weight-light: light;
  --font-weight-bold: bold;

  --headings-font-family: inherit;
  --headings-font-weight: 500;
  --headings-line-height: 1.1;
  --headings-color: inherit;
  --headings-small-color: var(--grey-light);

  --line-height-base: 1.4;
  --line-height-computed: calc(var(--font-size-base) * var(--line-height-base));
  /* End Typography */

  /* Transitions */
  --default-transition-duration: 0.25s;
  --default-transition-easing: ease-in-out;
  --transition-all: all var(--default-transition-duration)
    var(--default-transition-easing);

  --max-site-width: 1280px;
}

/* INSIGHT mobile header */

.overlayOn {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 25%;
  left: 0px;
  z-index: 2;
  display: block !important;
  background: var(--very-light-grey);
}

/*.close-icon {
  background: url("../../images/close-icon.png") top left no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  display: block;
  right: 13px;
  top: 14px;
  position: absolute;
}*/

.overlay {
  display: none;
}

.user-name {
  font-size: var(--font-size-h1);
  color: var(--black);
  letter-spacing: 1.2px;
}

.info-box {
  margin-left: 20px;
  margin-top: 40px;
}

.link-setting {
  display: block;
  letter-spacing: 1px;
  line-height: 26px;
  font: var(--font-family-simplon);
  font-size: var(--font-size-large);
}

.mobile-header-container {
  position: relative;
  background: var(--blue);
  height: 50px;
  text-align: center;
  padding: 0 50px;
}

.header-logo-container {
  padding: 14px 5px 10px 5px; /* Accommodate odd baseline of Simplon */
}

.header-logo {
  display: block;
  font-family: var(--font-family-simplon);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2.2px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--white) !important;
  & sup {
    font-size: 10px;
    letter-spacing: 1.2px;
    line-height: 13px;
    margin-left: 3px;
    vertical-align: super;
    top: auto;
  }
}

/*.mobile-header-menu {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  padding: 13px;
  background-color: transparent;
  border: none;
}

.mobile-header-search-menu-item {
  background: url("../../images/mobile-header-search-icon.png") top left no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  display: block;
  right: 13px;
  top: 14px;
  position: absolute;
}*/

.search-box-container {
  padding: 0 !important;
  height: inherit;
}

.show-search-bar {
  display: block !important;
}

.header-container {
  height: 40px;
  background-color: #007ea8;
  display: none;
}

@media (min-width: 600px) {
  .header-container {
    display: block;
  }

  .mobile-header-container {
    display: none;
  }

  .learn-more {
    font-weight: bold;
    padding-top: 9px;
    display: inline-block;
  }

  .header-logo-container {
    padding: 9px 20px 5px 20px; /* Accommodate odd baseline of Simplon */
  }

  .search-box-container {
    display: inline-block;
    width: calc(100% - 250px);
  }
}
